import React, { useEffect, useState } from 'react'
import { FaCcMastercard, FaCcStripe, FaCcVisa } from 'react-icons/fa'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import Button from './UI/Button'
import { FiCheck, FiCreditCard, FiDownload, FiEdit, FiEdit2, FiEdit3, FiTrash, FiTrash2 } from 'react-icons/fi'
import BrandIcon from './UI/BrandIcon'
import Popup from './UI/Popup'
import Checkbox from './UI/Checkbox'
import I18n from './UI/I18n'
import Price from './UI/Price'

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#2e3238', borderColor: '#e3e5e8', fontFamily: `'Nunito'`, fontWeight: '300', fontSmoothing: 'antialiased', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)', fontSize: '16px', '::placeholder': { fontWeight: '300', color: '#777777' }
    },
    invalid: { color: '#fa755a', iconColor: '#fa755a' }
  }
}

const brands = {
  visa: <FaCcVisa className="text-2xl absolute bottom-0 right-0 mr-3 mb-2" />,
  mastercard: <FaCcMastercard className="text-2xl absolute bottom-0 right-0 mr-3 mb-2" />
}

export default function StripeCard({ dealer, saveCard, deleteCards, payment, onPaymentConfirmed, closePay }) {
  const [show, setShow] = useState()
  const [cgu, setCgu] = useState(false)
  const [cardError, setCardError] = useState()
  const stripe = useStripe()
  const elements = useElements()
  const card = (dealer.stripeCache.paymentMethods.data[0] || {}).card
  
  useEffect(() => {
    console.log(payment)
    if (payment && stripe && payment.status === 'requires_action')
      stripe.confirmCardPayment(payment.client_secret).then(res => onPaymentConfirmed(res.paymentIntent)).catch(e => console.log('error', e))
  }, [payment, stripe])

  const trigger = async () => {
    const method = await stripe.createPaymentMethod({ type: 'card', card: elements.getElement(CardElement) })
    saveCard(method.paymentMethod.id)
    setShow(false)
  }

  const pay = async () => {
    try {
      const card = elements.getElement(CardElement)
      const result = await stripe.confirmCardPayment(payment.client_secret, { payment_method: { card } })
      if (result.error) throw result.error.message
      setCardError(null)
      if (result.paymentIntent.status === 'succeeded') {
        setShow(false)
        await onPaymentConfirmed(result.paymentIntent)
      }
    } catch (e) {
      setCardError(`${e}`)
      throw e
    }
  }

  return (
    <>
      <div className="flex flex-col justify-center bg-white rounded shadow-md text-gray-600 py-4 px-6 m-2 lg:m-5 w-full lg:w-1/3+m">
        <div className="flex text-2xl lg:text-3xl mb-2">
          <div className="relative h-10 w-10 mr-4 flex-shrink-0"><BrandIcon Icon={FiCreditCard} /></div>
          Paiement
        </div>
        {!card && 
          <div>
            <div className="text-base leading-tight">Aucune carte enregistrée</div>
            <small className="text-xs leading-tight block mt-2">Vous n'êtes pas obligé d'ajouter votre carte, vous pouvez également renseigner vos coordonnées bancaires pour chaque paiement</small>
            <Button variant="secondary" className="mt-4" onClick={() => setShow(true)}>Ajouter</Button>
          </div>
        }
        {card &&
          <div className="flex items-center justify-center text-base font-thin mt-2">
            <div className="bg-pink font-mono relative rounded-lg text-white px-3 py-2 w-40">
              <div className="flex justify-between items-center text-base"><span className="opacity-50 -mb-1 mr-1 text-xs" style={{ letterSpacing: '-0.1em' }}>**** **** ****</span><span>{card.last4}</span></div>
              <div className="text-sm mt-4">{card.exp_month}/{card.exp_year}</div>
              {brands[card.brand]}
            </div>
            <div>
              <Button variant="icon" Icon={FiEdit3} className="my-1 mx-2" onClick={() => setShow(true)}></Button>
              <Button variant="icon" Icon={FiTrash2} className="my-1 mx-2" onClick={deleteCards}></Button>
            </div>
          </div>
        }
      </div>
      {show &&
        <Popup title="Moyen de paiement Smiley Box" close={() => setShow(false)}>
          <div className="max-w-screen-sm flex flex-col justify-center items-center">
            <CardElement
              className="form-input py-3 mt-6 w-full"
              onChange={e => { setCardError(e.error ? e.error.message : e.complete ? false : 'Incomplet') }}
              options={CARD_ELEMENT_OPTIONS} />
            <div className="flex items-center w-full my-8 text-sm text-left leading-tight lg:text-base">
              <Checkbox checked={cgu} onClick={() => setCgu(!cgu)} className="mr-4" />
              <div className="text-gray-500"><I18n html>form.payment.cgu</I18n></div>
            </div>
            {cardError && ![true, 'Incomplet'].includes(cardError) &&
              <div className="leading-none font-light text-sm text-red opacity-75 mb-2">
                {cardError}
              </div>
            }
            <Button onClick={trigger} disabled={cardError || !cgu}>Sauvegarder</Button>
          </div>
        </Popup>
      }
      {payment && stripe && payment.status === 'requires_payment_method' &&
        <Popup title="Paiement ponctuel Smiley Box" close={() => closePay()}>
          <div className="max-w-screen-sm flex flex-col justify-center items-center">
            <CardElement
              className="form-input py-3 mt-6 w-full"
              onChange={e => { setCardError(e.error ? e.error.message : e.complete ? false : 'Incomplet') }}
              options={CARD_ELEMENT_OPTIONS} />
            <div className="flex items-center w-full my-8 text-sm text-left leading-tight lg:text-base">
              <Checkbox checked={cgu} onClick={() => setCgu(!cgu)} className="mr-4" />
              <div className="text-gray-500"><I18n html>form.payment.cgu</I18n></div>
            </div>
            {cardError && ![true, 'Incomplet'].includes(cardError) &&
              <div className="leading-none font-light text-sm text-red opacity-75 mb-2">
                {cardError}
              </div>
            }
            <Button onClick={pay} disabled={cardError || !cgu}>Payer&nbsp;<Price price={payment} /></Button>
          </div>
        </Popup>
      }
    </>
    
  )
}
